.leaflet-container{
background-color: #d5e7eb;

}

.leaflet-tooltip  {
    background:transparent;
    border: none;
      box-shadow: none;
    color:"#3b312b";
    font-size:15px;
    line-height:24px;
    border-radius: 0px;
    


    }

.state-tooltip{
  font-family: "blackadder ITC";
  font-size:24px;
  line-height:0px;
  font-weight: bold;
  transform: none !important;
  position: absolute !important;
  top: 650px !important;
  left: 0 !important;
}
.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {

  display:none;
}
  
.leaflet-tooltip-left{
  margin-left:0px;

}

.leaflet-tooltip-right{
  margin-right:0px;
}

.stateMarker div.icon {
 
 color:yellow;
 
}


.markerInfo{
  position: relative;
  height:50%;
}


.dndMap {
  min-height: 700px;
 
  height: 100%;
  /* Fallback for vmin */
  padding: 0px 1rem 1rem 1rem;
  padding: 0px 1vmin 1vmin 1vmin;
}

.mapView{
  min-height: 500px;
  height: 100%;
  width: 100%;

}

.map { 
 height: 100%;
}

.mycluster{
  display: block;
    height: 20px;
    width: 20px;
    -moz-border-radius: 50%; /* or 50% */
    border-radius: 50%; /* or 50% */
    border:1px solid #fff;
    color: white;
    text-align: center;
    font-size: 2em;
}

.mycluster:after{
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,1) 100%);
  border-radius: 50%;
  position: absolute;
  top: 0; left: 0;
}

.dndMenu {
  list-style-type: none;
  margin-left:auto;
  margin-right:auto;
 
}

.dndMenu  li{
  float: left;
  text-align:center;
line-height: 30px;
  border-right: 1px solid gray;
  width: 150px;
  padding:10px;
  margin:auto;
  display:block;
}

.dndMenu li a {
  color:#8c8c8c;

}

.raceList{
  list-style-type: none;
  margin-left:auto;
  margin-right:auto;
  text-align: center;
  border:1px solid gray;
}


.raceList  li{
  float:left;
  text-align: center;
  display: block;
  padding:10px;
  margin:auto;
  
}
.continentList{
  list-style-type: none;
  margin-left:auto;
  margin-right:auto;

}


.continentList  li{
  float: left;
  text-align:center;
line-height: 30px;
  border-right: 1px solid gray;
  width: 150px;
  padding:10px;
  margin:auto;
  display:block;
  
}
.raceList li img{
  height:100px;
}

.detail-table { 
  margin-left:auto;
  margin-right:auto;
  display:table;         
  width:auto;         
        
        
  border-spacing:5px;/*cellspacing:poor IE support for  this*/
}

.detail-table-row{
  display:table-row;
  width:auto;
  clear:both;
  border-bottom:1px solid  #666666;  
 }
.detail-table-cell {
  
  float:left;/*fix for  buggy browsers*/
  display: table-cell;        
  width:200px;         
  
   }

   .left{
    text-align: left;
   }

   .right{
    text-align: right;
   }

   .collapse {
    display: none
  }

  .collapse.in {
    display: block
  }


  .guildList{
    list-style-type: none;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
    border:1px solid gray;
  }
  
  
  .guildList  li{
    float:left;
    text-align: center;
    display: block;
    padding:10px;
    margin:auto;
    
  }
  
  .guildList li img{
    height:100px;
  }


  .dndMapFilter {
    list-style-type: none;
    margin-left:auto;
    margin-right:auto;
    float:center;
   
  }
  
  .dndMapFilter  li{
    float: left;
    text-align:center;
  line-height: 30px;
    border-right: 1px solid gray;
    width: 25%;
    padding:10px;
    margin:auto;
    display:block;
  }
  
  .dndMapFilter li a {
    color:#8c8c8c;
  
  }