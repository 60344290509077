$blue-dk: #002A50;
$blue:    #22659c;

@mixin shadow($level: 1) {
	@if $level == 1 {box-shadow: 0 1px 3px rgba(black,0.12), 0 1px 2px rgba(black,0.24);}
	@else if $level == 2 {box-shadow: 0 3px 6px rgba(black,0.16), 0 3px 6px rgba(black,0.23);}
	@else if $level == 3 {box-shadow: 0 10px 20px rgba(black,0.19), 0 6px 6px rgba(black,0.23);}
	@else if $level == 4 {box-shadow: 0 14px 28px rgba(black,0.25), 0 10px 10px rgba(black,0.22);}
	@else if $level == 5 {box-shadow: 0 19px 38px rgba(black,0.30), 0 15px 12px rgba(black,0.22);}
}

* {margin: 0; padding: 0;}


.org-chart {
	display:flex;
	justify-content:center;
	max-width:1500px !important;
	ul {
		padding-top: 20px;
		position: relative;
		transition: all 0.5s;
		ul::before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			border-left: 1px solid #ccc;
			width: 0;
			//height: 20px;
		}
	}
	li {
		float: left;
		text-align: center;
		list-style-type: none;
		position: relative;
		padding: 20px 10px;
		transition: all 0.5s;
		&::before,&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 50%;
			border-top: 1px solid #ccc;
			width: 50%;
			height: 20px;
		}
		&::after {
			right: auto;
			left: 50%;
			border-left: 1px solid #ccc;
		}
		&:only-child::after, &:only-child::before {
			display:none;
		}
		&:only-child {
			padding-top:0;
		}
		&:first-child::before, &:last-child::after {
			border: 0 none;
		}
		&:last-child::before {
			border-right: 1px solid #ccc;
			border-radius: 0 5px 0 0;
		}
		&:first-child::after {
			border-radius: 5px 0 0 0;
		}
		.user {
			text-decoration: none;
			color: #666;
			//font-family: arial, verdana, tahoma;
			display: inline-block;
			padding:20px 10px;
			transition: all 0.5s;
			background:#fff;
			min-width:120px;
			border-radius:6px;
			//display:flex;
			//align-items:center;
			//justify-content:center;
			//flex-direction:column;
			@include shadow(1);
			&:hover,&:hover + ul li .user {
				//background: lighten(#22659c,30%);
				background:lighten($blue,45%);
				color: $blue-dk;
				//color:white;
				//@include shadow(2);
				transition:all 0.15s;
				transform:translateY(-5px);
				box-shadow:inset 0 0 0 3px lighten($blue,30%),0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
				img {
					box-shadow:0 0 0 5px lighten($blue,20%);
				}
			}
			&:hover {
				+ ul li::after,
				+ ul li::before,
				+ ul::before,
				+ ul ul::before {
					border-color:  #94a0b4;
				}
			}
			> div, > a {
				font-size:12px;
			}
			img {
				margin:0 auto;
				max-width:60px;
				max-width:60px;
				width:60px;
				height:60px;
				border-radius:50%;
				box-shadow:0 0 0 5px #aaa;
			}
			.name {
				font-size:16px;
				margin:15px 0 0;
				font-weight:300;
			}
			.role {
				font-weight:600;
				margin-bottom:10px;
				margin-top:5px;
				max-width: 80px;
			
				margin-left:auto;
				margin-right:auto;
			}
			.manager {
				font-size:12px;
			  color:#b21e04;
			}
		}
	}
}

