.recharts-default-tooltip[style]{
    margin:10px 5px !important;
font-size:14px;
font-weight: bold;
display:block;
background-color:#212529 !important;
border:1px solid #8c8c8c !important;
padding: 5px !important;
color:#ec008c;

}
.recharts-default-tooltip p{
    margin:5px;
 
}

.recharts-tooltip-item span{
    margin:5px;
 font-size:10px;

}

.services .icon span{
    font-size:10px;
    color:#ec008c;
}

.row p{
    margin-top:10px;
}

