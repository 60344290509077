.loadMoreButtom:hover{
 cursor: pointer;
}



.single-blog p{
   
    margin-bottom: 40px;
}

.categoryButton {
	box-shadow:inset 0px 1px 3px 0px #121212;
	background:linear-gradient(to bottom, #141515 5%, #121212 100%);
	background-color:#141515;
	border-radius:5px;
	border:1px solid #121212;
	display:inline-block;
    text-align: center;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:11px 23px;
	text-decoration:none;
	text-shadow:0px -1px 0px #757575;
}
.categoryButton:hover {
	background:linear-gradient(to bottom, #757575 5%, #757575 100%);
	background-color:#757575;
}
.categoryButton:active {
	position:relative;
	top:1px;
}

.selectedBtn {
    background:linear-gradient(to bottom, #757575 5%, #757575 100%);
	background-color:#757575;
}

figure img{
	max-width: 800px;
	overflow: hidden;
}

figure img:hover {
	transform: scale(1.5);
  }


  .backlink{
	color:rgb(156, 156, 156);
	font-size:15px;
  }